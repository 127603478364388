import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer/Footer";
import HeaderHome from "./Header/HeaderHome";
import Header from "./Header/Header";
import FooterAuth from "./Footer/FooterAuth";
import { useConfig } from "../context/ConfigContext"; // นำเข้า useConfig

const Layout = () => {
  const location = useLocation();
  const { checkLogin, isLoggedIn } = useConfig(); // ใช้ useConfig เพื่อเรียก checkLogin

  const [isLoggedInLocal, setIsLoggedInLocal] = useState(
    localStorage.getItem("login") === "true"
  );

  useEffect(() => {
    const checkLoginStatus = () => {
      const loginStatus = localStorage.getItem("login") === "true";
      setIsLoggedInLocal(loginStatus); // อัปเดต state เมื่อ login เปลี่ยนแปลง
    };

    // Event listener สำหรับเช็คการเปลี่ยนแปลงของ localStorage
    window.addEventListener("storage", checkLoginStatus);

    // เรียก checkLogin ทุกๆ 5 นาที
    const intervalId = setInterval(() => {
      checkLogin(); // เรียก checkLogin จาก useConfig
    }, 300000); // 300000 milliseconds = 5 นาที

    return () => {
      window.removeEventListener("storage", checkLoginStatus);
      clearInterval(intervalId); // เคลียร์ interval เมื่อ component ถูก unmount
    };
  }, [checkLogin]); // Dependency on checkLogin

  return (
    <div>
      {location.pathname === "/" || location.pathname === "/contactus" ? (
        <HeaderHome />
      ) : !["/register", "/otp", "/register-success", "/login"].some((path) =>
          location.pathname.includes(path)
        ) && !location.pathname.includes("/passcode") ? (
        <Header />
      ) : null}
      <main>
        <Outlet />
      </main>
      {!["/register", "/otp", "/register-success", "/login"].some((path) =>
        location.pathname.includes(path)
      ) && !location.pathname.includes("/passcode") ? (
        <Footer />
      ) : null}
      <div className="bg_show"></div>
      {isLoggedIn ? <FooterAuth /> : null} {/* แสดง FooterAuth ถ้า logged in */}
    </div>
  );
};

export default Layout;
