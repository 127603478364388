export const myConfig = {
  // apiUrl: "https://btdprofrontend344325.azurewebsites.net/api/GameCenterWebApi",
  // apiUrl: "https://btodaytest224466.azurewebsites.net/api/GameCenterWebApi",
  apiUrl:
    "https://huaydailly66frontend57855861.azurewebsites.net/api/GameCenterWebApi",
  imgUrl: "https://www.huaydailly.com",
  affUrl: "https://www.huaydailly.com/#/register",
  // affUrl: 'http://localhost:3000/#/affliate',
  apiKey: "1b50061a-e9e2-499e-8a23-b6f43737c510",
  apiKeyAuth: "4855569c-8cc3-4e80-8d3d-d3a1b1d7fc39",
  timeInterval: 30000,
  timeIntervalHome: 30000,
  timeIntervalScorer: 60000,
  timeIntervalStat: 90000,
  IMG_LOGO_URL: "https://www.huaydailly.com/assets/img/logo.png",
  BASE_WEB_URL: "https://www.huaydailly.com/",
  // nodeUrl_TEST: "http://localhost:3300",
  // nodeUrl: "https://wschat.bettoday.me",
  supportChatId: "6623d60add4659939c7af1c7",
};
