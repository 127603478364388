import React from "react";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../../context/ConfigContext";

const Header = () => {
  const navigate = useNavigate();

  const { logo } = useConfig();

  return (
    <div>
      <header className="header d-none d-md-block bg_blur ins_sd">
        <div className="container">
          <div className="box_row justify-content-between">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
              className="logo_links"
            >
              <img src={logo} alt="Logo-M" />
            </a>
            <div className="link_menu">
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
                className="link_item text-center c_tertiary"
              >
                <h5 className="font_md">หน้าแรก</h5>
                <h6>Home</h6>
              </a>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dashboard");
                }}
                className="link_item text-center c_tertiary"
              >
                <h5 className="font_md">กระเป๋าเงิน</h5>
                <h6>Wallet</h6>
              </a>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/promotion");
                }}
                className="link_item text-center c_tertiary"
              >
                <h5 className="font_md">โปรโมชั่น</h5>
                <h6>Promotion</h6>
              </a>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/affiliate");
                }}
                className="link_item text-center c_tertiary"
              >
                <h5 className="font_md">แนะนำเพื่อน</h5>
                <h6>Invite Friend</h6>
              </a>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/contact-us");
                }}
                className="link_item text-center c_tertiary"
              >
                <h5 className="font_md">ติดต่อเรา</h5>
                <h6>Contact Us</h6>
              </a>
            </div>
          </div>
        </div>
      </header>
      <header className="header_m d-block d-md-none bg_blur ins_sd">
        <div className="container">
          <div className="box_row justify-content-between">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
              className="logo_links"
            >
              {/* <img src="./assets/img/logo-m.png" alt="Logo-M" /> */}
              <img src={logo} alt="Logo-M" />
            </a>
            <div>
              <input className="menu_btn" type="checkbox" id="menuBTN" />
              <label className="menu_icon" htmlFor="menuBTN">
                <span className="navicon" />
              </label>
              <div className="link_menu_m bg_blur">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }}
                  className="link_item text-center c_tertiary"
                >
                  <h5 className="font_md">หน้าแรก</h5>
                </a>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/promotion");
                  }}
                  className="link_item text-center c_tertiary"
                >
                  <h5 className="font_md">โปรโมชั่น</h5>
                </a>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/affiliate");
                  }}
                  className="link_item text-center c_tertiary"
                >
                  <h5 className="font_md">แนะนำเพื่อน</h5>
                </a>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/contact-us");
                  }}
                  className="link_item text-center c_tertiary"
                >
                  <h5 className="font_md">ติดต่อเรา</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
