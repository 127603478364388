import React from "react";
import { useNavigate } from "react-router-dom";

import Marquee from "../Marquee/Marquee";
import { useConfig } from "../../context/ConfigContext";
import apiRequest from "../../common/api";

const HeaderHome = () => {
  const navigate = useNavigate();

  const { logo } = useConfig();

  return (
    <header className="mt-3 mb-2">
      <div className="container">
        <div className="box_row justify-content-between d-none d-md-flex">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
            className="img_logo"
          >
            <img src={logo} alt="Logo-M" />
          </a>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              navigate("/contactus");
            }}
            className="link_cnt"
          >
            <div className="img">
              <img src="./assets/img/line_icon.png" alt="LineIcon" />
            </div>
            <span className="ms-2">ติดต่อเรา</span>
          </a>
        </div>
        <div className="box_row w-100 py-0 flex-nowrap border_5 bg_secondary p-1 bx_sd mt-2 mt-md-3">
          <div className="ps-2 pe-1 py-2">
            <img
              src="./assets/img/volumeup.svg"
              alt="Icon-Volume"
              style={{ width: "70%" }}
              className="wip_wup"
            />
          </div>

          <Marquee />
        </div>
      </div>
    </header>
  );
};

export default HeaderHome;
