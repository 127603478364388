import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { myConfig } from "../constants/constants";

const ConfigContext = createContext();

export const useConfig = () => {
  return useContext(ConfigContext);
};

export const ConfigProvider = ({ children }) => {
  const navigate = useNavigate();

  const [logo, setLogo] = useState(
    localStorage.getItem("wEBSITE_LOGO_URL") || ""
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogout = () => {
    const actionIdLogout = 2003;
    let url = `${myConfig.apiUrl}/FrontEndAuth`;

    axios({
      method: "post",
      url: url,
      data: qs.stringify({
        key: myConfig.apiKeyAuth,
        actionId: actionIdLogout,
        userSessionKey: localStorage.getItem("userSessionKey"),
        memberId: localStorage.getItem("memberId"),
      }),
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      ClearLocalStorage();
      sessionStorage.clear();
      navigate("/");
      // toast.success(res.data.description, {
      //   position: 'bottom-right',
      //   autoClose: 5000,
      // });
    });
  };

  const ClearLocalStorage = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("mobileNo");
    localStorage.removeItem("login");
    localStorage.removeItem("memberId");
    localStorage.removeItem("userSessionKey");
    localStorage.removeItem("loginOnText");
    localStorage.removeItem("bankAccountName");
    localStorage.removeItem("bankAccountNumber");
    localStorage.removeItem("bankShortNameEn");
    localStorage.removeItem("bankShortNameTh");
    localStorage.removeItem("trueMoneyAccountName");
    localStorage.removeItem("trueMoneyWalletID");
    localStorage.removeItem("agentBankAccountName");
    localStorage.removeItem("agentBankAccountNumber");
    localStorage.removeItem("agentBankShortNameEn");
    localStorage.removeItem("agentBankShortNameTh");
    localStorage.removeItem("agentTrueMoneyAccountName");
    localStorage.removeItem("agentTrueMoneyWalletID");
    localStorage.removeItem("webSiteLogo_URL");
    localStorage.removeItem("balance");
    // localStorage.removeItem('parentId');
    localStorage.removeItem("activeTab");
    localStorage.removeItem("agentBankImageURL");
    localStorage.removeItem("memberBankImageURL");
    localStorage.removeItem("scoreAmount");
  };

  const currencyFormat = (num) => {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const checkLogin = () => {
    const actionIdLogout = 2059;
    let url = `${myConfig.apiUrl}/FrontEndAuth`;

    axios({
      method: "post",
      url: url,
      data: qs.stringify({
        key: myConfig.apiKeyAuth,
        actionId: actionIdLogout,
        userSessionKey: localStorage.getItem("userSessionKey"),
        memberId: localStorage.getItem("memberId"),
      }),
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log("checkLogin:", res.data);
      if (res.data.statusCode === 1) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        // handleLogout();
      }
    });
  };

  useEffect(() => {
    const actionIdLogoImg = 1023;

    const getLogoImage = async () => {
      let url = `${myConfig.apiUrl}/FrontEnd`;

      try {
        const res = await axios.post(
          url,
          qs.stringify({
            key: myConfig.apiKey,
            actionId: actionIdLogoImg,
          }),
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        );

        if (res.data.statusCode === 1) {
          localStorage.setItem(
            "wEBSITE_LOGO_URL",
            res.data.message.wEBSITE_LOGO_URL
          );
          setLogo(res.data.message.wEBSITE_LOGO_URL);
        } else {
          const defaultLogo = `${myConfig.imgUrl}/assets/img/logo.png`;
          localStorage.setItem("wEBSITE_LOGO_URL", defaultLogo);
          setLogo(defaultLogo);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
        const defaultLogo = `${myConfig.imgUrl}/assets/img/logo.png`;
        localStorage.setItem("wEBSITE_LOGO_URL", defaultLogo);
        setLogo(defaultLogo);
      }
    };

    getLogoImage();
    checkLogin();
  }, []);

  const value = {
    logo,
    handleLogout,
    currencyFormat,
    checkLogin,
    isLoggedIn,
  };

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};
