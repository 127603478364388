import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import packageJson from "../package.json";
import withSessionCheck from "./hoc/withSessionCheck";
import withAuth from "./hoc/withAuth"; // นำเข้า withAuth
import Layout from "./components/Layout";
import OTPProtectedRoute from "./hoc/OTPProtectedRoute";

const Home = React.lazy(() => import("./pages/Home/Home"));
const ContactUs = React.lazy(() => import("./pages/ContactUs/ContactUs"));

const ViewAs = React.lazy(() => import("./pages/Login/ViewAs"));

const Login = React.lazy(() => import("./pages/Login/Login"));
const Register = React.lazy(() => import("./pages/Register/Register"));
const RegisterOtp = React.lazy(() => import("./pages/Register/RegisterOtp"));
const RegisterBank = React.lazy(() => import("./pages/Register/RegisterBank"));
const RegisterSuccess = React.lazy(() =>
  import("./pages/Register/RegisterSuccess")
);
const Passcode = React.lazy(() => import("./pages/Passcode/Passcode"));

const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const MyPoints = React.lazy(() => import("./pages/MyPoints/MyPoints"));

const Deposit = React.lazy(() => import("./pages/Deposit/Deposit"));
const DepositTransfer = React.lazy(() =>
  import("./pages/Deposit/DepositTransfer")
);
const Withdraw = React.lazy(() => import("./pages/Withdraw/Withdraw"));

// Huay
const LobbyHuay = React.lazy(() => import("./pages/Huay/LobbyHuay/LobbyHuay"));
const LobbyHuayYekeeList = React.lazy(() =>
  import("./pages/Huay/LobbyHuay/LobbyHuayYekeeList")
);
const HuayBet = React.lazy(() => import("./pages/Huay/HuayBet/HuayBet"));
const HuayBetSet = React.lazy(() =>
  import("./pages/Huay/HuayBetSet/HuayBetSet")
);
const HuaySetTerm = React.lazy(() =>
  import("./pages/Huay/HuaySet/HuaySetTerm")
);

const CreateNumberSet = React.lazy(() =>
  import("./pages/Huay/NumberSetList/CreateNumberSet")
);

const CreateNumberWin = React.lazy(() =>
  import("./pages/Huay/NumberSetList/CreateNumberWin")
);
const TicketDetail = React.lazy(() =>
  import("./pages/Huay/MyPoll/TicketDetail")
);

// Game
const LobbyGame = React.lazy(() => import("./pages/Games/LobbyGame"));

const History = React.lazy(() => import("./pages/History/History"));
const Affiliate = React.lazy(() => import("./pages/Affiliate/Affiliate"));
const Cashback = React.lazy(() => import("./pages/Cashback/Cashback"));
const Promotion = React.lazy(() => import("./pages/Promotion/Promotion"));
const Profile = React.lazy(() => import("./pages/Profile/Profile"));
const Reward = React.lazy(() => import("./pages/Reward/Reward"));
const HowTo = React.lazy(() => import("./pages/HowTo/HowTo"));

global.appVersion = packageJson.version;

const App = () => {
  const Loaderimg = () => {
    return (
      <div id="global-loader">
        <img
          src={require("./assets/images/loader.svg").default}
          className="loader-img"
          alt="Loader"
        />
      </div>
    );
  };

  const refreshCacheAndReload = () => {
    console.log("Clearing cache and hard reloading...");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        // console.log(names)
        for (let name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    window.location.reload(true);
  };

  const check_version = () => {
    let latestVersion = localStorage.getItem("metaVersion");

    if (latestVersion === null || latestVersion === undefined) {
      latestVersion = "0.0.0";
      localStorage.setItem("metaVersion", latestVersion); // Set '0.0.0' if it's the first time
    }

    let currentVersion = global.appVersion;

    let shouldForceRefresh = latestVersion !== currentVersion;
    if (shouldForceRefresh) {
      console.log(
        `We have a new version - ${currentVersion}. Should force refresh`
      );

      localStorage.setItem("metaVersion", currentVersion);
      refreshCacheAndReload();
    } else {
      console.log(
        `You already have the latest version - ${latestVersion}. No cache refresh needed.`
      );
    }
  };

  useEffect(() => {
    check_version();
    // get_logo_image();
  }, []);

  const WrappedDashboard = withAuth(withSessionCheck(Dashboard));
  const WrappedContactUs = withAuth(withSessionCheck(ContactUs));
  const WrappedMyPoints = withAuth(withSessionCheck(MyPoints));
  const WrappedDeposit = withAuth(withSessionCheck(Deposit));
  const WrappedDepositTransfer = withAuth(withSessionCheck(DepositTransfer));
  const WrappedWithdraw = withAuth(withSessionCheck(Withdraw));

  const WrappedLobbyHuay = withAuth(withSessionCheck(LobbyHuay));
  const WrappedLobbyHuayYk = withAuth(withSessionCheck(LobbyHuayYekeeList));
  const WrappedHuayBet = withAuth(withSessionCheck(HuayBet));
  const WrappedHuayBetSet = withAuth(withSessionCheck(HuayBetSet));
  const WrappedHuaySetTerm = withAuth(withSessionCheck(HuaySetTerm));
  const WrappedCreateSet = withAuth(withSessionCheck(CreateNumberSet));
  const WrappedCreateWinNumber = withAuth(withSessionCheck(CreateNumberWin));
  const WrappedTicketDetail = withAuth(withSessionCheck(TicketDetail));

  const WrappedLobbyGame = withAuth(withSessionCheck(LobbyGame));
  const WrappedReward = withAuth(withSessionCheck(Reward));
  const WrappedProfile = withAuth(withSessionCheck(Profile));
  const WrappedPromotion = withAuth(withSessionCheck(Promotion));
  const WrappedCashback = withAuth(withSessionCheck(Cashback));
  const WrappedAffiliate = withAuth(withSessionCheck(Affiliate));
  const WrappedHistory = withAuth(withSessionCheck(History));

  return (
    <React.Suspense fallback={Loaderimg()}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/:parentId" element={<Home />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/contact-us" element={<WrappedContactUs />} />

          <Route path="/Login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/:refUser" element={<Register />} />
          {/* <Route path="/otp" element={<RegisterOtp />} /> */}
          <Route
            path="/otp"
            element={
              <OTPProtectedRoute>
                <RegisterOtp />
              </OTPProtectedRoute>
            }
          />
          {/* <Route path="/register-bank" element={<RegisterBank />} /> */}
          <Route
            path="/register-bank"
            element={
              <OTPProtectedRoute>
                <RegisterBank />
              </OTPProtectedRoute>
            }
          />
          {/* <Route path="/register-success" element={<RegisterSuccess />} /> */}
          <Route
            path="/register-success"
            element={
              <OTPProtectedRoute>
                <RegisterSuccess />
              </OTPProtectedRoute>
            }
          />
          <Route path="/passcode/:pinType" element={<Passcode />} />

          <Route exact path="/viewas" element={<ViewAs />} />

          <Route path="/dashboard" element={<WrappedDashboard />} />
          <Route path="/mypoints" element={<WrappedMyPoints />} />
          <Route path="/deposit" element={<WrappedDeposit />} />
          <Route path="/deposit-confirm" element={<WrappedDepositTransfer />} />
          <Route path="/withdraw" element={<WrappedWithdraw />} />

          <Route path="/lobby-huay" element={<WrappedLobbyHuay />} />
          <Route path="/lobby-huay-yk" element={<WrappedLobbyHuayYk />} />

          {/* <Route path="/huaybet" element={<HuayBet />} /> */}
          <Route
            path="/huaybet/:huayType/:huayLottoId/:huayLottoRoundId"
            element={<WrappedHuayBet />}
          />
          <Route
            path="/huaysetterm/:huayLottoId"
            element={<WrappedHuaySetTerm />}
          />
          <Route
            path="/huaybetset/:getLottoRoundId/:getLottoSetId"
            element={<WrappedHuayBetSet />}
          />

          <Route
            path="/createnumberset/:getLottoRoundPlayerSaveRecordId"
            element={<WrappedCreateSet />}
          />

          <Route
            path="/createnumberswin/:getLottoRoundPlayerSaveRecordId"
            element={<WrappedCreateWinNumber />}
          />

          <Route
            path="/ticket-detail/:lottoRoundPlayerRecordId"
            element={<WrappedTicketDetail />}
          />

          <Route path="/lobby-game" element={<WrappedLobbyGame />} />

          <Route path="/history" element={<WrappedHistory />} />
          <Route path="/affiliate" element={<WrappedAffiliate />} />
          <Route path="/cashback" element={<WrappedCashback />} />
          <Route path="/promotion" element={<WrappedPromotion />} />
          <Route path="/profile" element={<WrappedProfile />} />
          <Route path="/rewards" element={<WrappedReward />} />
          <Route path="/guide" element={<HowTo />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default App;
