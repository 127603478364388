import axios from "axios";
import qs from "qs";
import { myConfig } from "../constants/constants";

const apiRequest = async (url, method, actionId, data = {}, navigate) => {
  const SERVER_URL = myConfig.apiUrl;
  const API_KEY = url === "FrontEnd" ? myConfig.apiKey : myConfig.apiKeyAuth;

  // Default headers
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  };

  // Create request payload
  let payload;
  if (url === "FrontEndAuth") {
    payload = qs.stringify({
      key: API_KEY,
      actionId: actionId,
      userSessionKey: localStorage.getItem("userSessionKey"),
      memberId: localStorage.getItem("memberId"),
      message: JSON.stringify(data),
    });
  } else {
    payload = qs.stringify({
      key: API_KEY,
      actionId: actionId,
      message: JSON.stringify(data),
    });
  }

  try {
    const response = await axios({
      method: method || "post", // default to POST if not provided
      url: `${SERVER_URL}/${url}`,
      data: payload,
      headers: headers,
    });

    if (response.data.statusCode === 1) {
      return {
        statusCode: response.data.statusCode,
        description: response.data.description,
        message: response.data.message,
      };
    } else if (response.data.statusCode === 43) {
      // Navigate to login if session expired or unauthorized
      if (navigate) {
        navigate("/login", { replace: true });
      }
    } else {
      return {
        statusCode: response.data.statusCode,
        description: response.data.description,
        message: response.data.message,
      };
      // throw new Error('Error in API response');
    }
  } catch (error) {
    console.error("API Request Failed:", error);
    throw error;
  }
};

export default apiRequest;
