import React from 'react';
import { Navigate } from 'react-router-dom';
import { useOTP } from '../context/OtpContext'; // นำเข้าจาก Context ที่สร้างไว้

const OTPProtectedRoute = ({ children }) => {
  // const { isOTPVerified } = useOTP();
  const { isOtpSent } = useOTP();

  // console.log('isOTPVerified', isOTPVerified);
  // // ถ้ายังไม่ได้ยืนยัน OTP ให้ Redirect ไปหน้า /register
  // if (!isOTPVerified) {
  //   console.log("redirect to /register");
  //   return <Navigate to="/register" replace />;
  // }
  
  console.log('isOTPVerified', isOtpSent);

  if (!isOtpSent) {
    return <Navigate to="/register" />;
  }

  return children;
};

export default OTPProtectedRoute;
