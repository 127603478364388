import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { myConfig } from '../constants/constants';

const withSessionCheck = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const checkSession = async () => {
        const url = `${myConfig.apiUrl}/FrontEndAuth`;

        // navigate('/login');
      };

      checkSession();
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };
};

export default withSessionCheck;
