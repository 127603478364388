import React, { useEffect, useRef, useState } from "react";
import apiRequest from "../../common/api";

const Marquee = () => {
  const marqueeRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const [isShow, setIsShow] = useState(false);
  const [messageList, setMessageList] = useState([]);

  const loadDataMarquee = async () => {
    const actionIdNoAuthMarquee = "1049";

    const requestData = {
      dateOnText: selectedDate,
    };

    try {
      const message = await apiRequest(
        "FrontEnd",
        "post",
        actionIdNoAuthMarquee,
        requestData
      );
      setIsShow(message.message.isShow);
      setMessageList(message.message.messageList);
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  const startMarqueeAfterDelay = () => {
    // คำนวณความยาวข้อความทั้งหมด
    const marqueeWidth = marqueeRef.current.scrollWidth;
    const containerWidth = marqueeRef.current.clientWidth;

    // คำนวณเวลาที่ต้องใช้ในการแสดงข้อความทั้งหมด
    const speed = 50; // สมมติว่า speed เป็น 50 px/s
    const duration = ((marqueeWidth + containerWidth) / speed) * 1000; // คำนวณเวลาเป็น ms

    // โหลดข้อมูลใหม่หลังจากเวลาที่ข้อความแสดงทั้งหมด
    setTimeout(() => {
      loadDataMarquee();
    }, duration);
  };

  useEffect(() => {
    loadDataMarquee();

    // โหลด marquee ใหม่เมื่อข้อความทั้งหมดแสดงแล้ว
    startMarqueeAfterDelay();

    return () => {
      clearTimeout(); // เคลียร์เมื่อ component ถูก unmount
    };
  }, [selectedDate]);

  return (
    <marquee
      direction="left"
      ref={marqueeRef}
      onMouseOver={() => marqueeRef.current.stop()}
      onMouseOut={() => marqueeRef.current.start()}
    >
      {isShow && messageList.length > 0
        ? messageList.map((item, index) => (
            <span style={{ marginRight: "20px" }} key={index}>
              {item}
            </span>
          ))
        : null}
    </marquee>
  );
};

export default Marquee;
