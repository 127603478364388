import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer bg_blur">
      <div className="container">
        <div className="box_row justify-content-center">
          <div className="link_menu d-none d-md-flex">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
              className="link_item text-center c_tertiary"
            >
              <h5 className="font_md">หน้าแรก</h5>
              <h6>Home</h6>
            </a>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
              className="link_item text-center c_tertiary"
            >
              <h5 className="font_md">ดาวน์โหลด</h5>
              <h6>Download</h6>
            </a>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                navigate("/promotion");
              }}
              className="link_item text-center c_tertiary"
            >
              <h5 className="font_md">โปรโมชั่น</h5>
              <h6>Promotion</h6>
            </a>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
              className="link_item text-center c_tertiary"
            >
              <h5 className="font_md">บทความ</h5>
              <h6>Blog</h6>
            </a>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                navigate("/affiliate");
              }}
              className="link_item text-center c_tertiary"
            >
              <h5 className="font_md">แนะนำเพื่อน</h5>
              <h6>Invite Friend</h6>
            </a>

            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                if (localStorage.getItem("login")) {
                  navigate("/contact-us");
                } else {
                  navigate("/contactus");
                }
              }}
              className="link_item text-center c_tertiary"
            >
              <h5 className="font_md">ติดต่อเรา</h5>
              <h6>Contact Us</h6>
            </a>
          </div>
          <div className="img_b my-4">
            <div className="items">
              <img
                src="./assets/img/b/Krung_Thai_Bank_logo.svg.png"
                alt="KrungThaiLogo"
              />
            </div>
            <div className="items">
              <img src="./assets/img/b/bualuang.png" alt="BKKBankLogo" />
            </div>
            <div className="items">
              <img src="./assets/img/b/SCB.png" alt="SCBLogo" />
            </div>
            <div className="items">
              <img src="./assets/img/b/KBANK.png" alt="KBankLogo" />
            </div>
            <div className="items">
              <img src="./assets/img/b/bay.png" alt="BayLogo" />
            </div>
            <div className="items">
              <img src="./assets/img/b/tmb.png" alt="TmbLogo" />
            </div>
            <div className="items">
              <img src="./assets/img/b/gsb.png" alt="GSBLogo" />
            </div>
            <div className="items">
              <img src="./assets/img/b/tbank.png" alt="TBankLogo" />
            </div>
            <div className="items">
              <img src="./assets/img/b/b5.png" alt="B5" />
            </div>
            <div className="items">
              <img src="./assets/img/b/lh.png" alt="LHLogo" />
            </div>
          </div>
          <div className="c_tertiary text-center">
            copyright 2023 - {new Date().getFullYear()} All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
