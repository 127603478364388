import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { currencyFormat } from "../../utils/moneyUtils";
import apiRequest from "../../common/api";

const FooterAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showDeposit, setShowDeposit] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [activeMenu, setActiveMenu] = useState("home");

  const [name, setName] = useState("");
  const [currentCashBalance2, setCurrentCashBalance2] = useState("");

  let localBankAccountNumber = localStorage.getItem("bankAccountNumber");
  const agentBankShortName = localStorage.getItem("agentBankShortNameEn");
  const agentBankAccountName = localStorage.getItem("agentBankAccountName");
  const agentBankAccountImage = localStorage.getItem("agentBankImageURL");
  const agentBankAccountNumber = localStorage.getItem("agentBankAccountNumber");
  const agentTrueMoneyAccountName = localStorage.getItem(
    "agentTrueMoneyAccountName"
  );
  const agentTrueMoneyWalletID = localStorage.getItem("agentTrueMoneyWalletID");

  const userBankShortName = localStorage.getItem("bankShortNameEn");
  // const memberBankImageURL = localStorage.getItem("memberBankImageURL");

  const [getBankName, setGetBankName] = useState("");
  const [getBankShortName, setGetBankShortName] = useState("");
  const [memberBankImageURL, setMemberBankImageURL] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [currentCashBalance, setCurrentCashBalance] = useState("");
  const [accountName, setAccountName] = useState("");
  const [minimumWithdrawalAmount, setMinimumWithdrawalAmount] = useState(0);
  const [withdrawable2, setWithdrawable2] = useState("");
  const [is_fixed_withdrawal_amount, setIs_fixed_withdrawal_amount] =
    useState("");
  const [isWithdrawableSummary, setIsWithdrawableSummary] = useState("");
  const [isWithdrawableSummaryMassage, setIsWithdrawableSummaryMassage] =
    useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  // ฟังก์ชันสำหรับปิด Deposit และ Withdraw Boxes
  const handleCloseDWClick = () => {
    setShowDeposit(false);
    setShowWithdraw(false);
  };

  // ฟังก์ชันสำหรับเปิด Deposit Box
  const handleOpenDepositClick = () => {
    setShowDeposit(true);
    setShowWithdraw(false); // ปิด Withdraw Box หากเปิดอยู่
    setActiveMenu("deposit"); // ตั้งค่าเมนู Deposit ให้ active
  };

  // ฟังก์ชันสำหรับเปิด Withdraw Box
  const handleOpenWithdrawClick = () => {
    setShowWithdraw(true);
    setShowDeposit(false); // ปิด Deposit Box หากเปิดอยู่
    setActiveMenu("withdraw"); // ตั้งค่าเมนู Withdraw ให้ active
  };

  // ฟังก์ชันเพื่อจัดการกับการเปลี่ยนเมนู และปิดกล่องฝาก/ถอนเงิน
  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    setShowDeposit(false);
    setShowWithdraw(false);
  };

  useEffect(() => {
    // ตรวจสอบ location.pathname เพื่อกำหนดค่า activeMenu สำหรับเส้นทางต่าง ๆ
    if (!showDeposit && !showWithdraw) {
      // เมื่อไม่ใช่ deposit หรือ withdraw popup
      if (location.pathname === "/dashboard" && activeMenu !== "home") {
        setActiveMenu("home");
      } else if (location.pathname === "/lobby-huay" && activeMenu !== "game") {
        setActiveMenu("game");
      } else if (location.pathname === "/promotion" && activeMenu !== "promo") {
        setActiveMenu("promo");
      }
    }
  }, [location.pathname, showDeposit, showWithdraw]); // เพิ่ม showDeposit และ showWithdraw เพื่อรีเซ็ต activeMenu

  const loadDataPlayerBalance = async () => {
    const actionIdPlayerMapping = 2057;

    const requestData = {};

    try {
      const message = await apiRequest(
        "FrontEndAuth",
        "post",
        actionIdPlayerMapping,
        requestData,
        navigate
      );
      //   console.log('message : ', message);

      if (message.statusCode === 1) {
        // setDataPlBal(message.message);
        // setBalTxt(message.message.balaceText);
        // setBalDtTxt(message.message.balanceDateTimeText);
        // setMemberId(message.message.memberId);
        // setWithdraw(message.message.balaceText);
        // setBalanceText(message.message.balaceText);
        setName(message.message.name);
        setBankAccountNumber(message.message.bankAccountNumber);
        // setWithdrawable2(message.message.withdrawable);
        setCurrentCashBalance2(message.message.currentCashBalance);
        // setIsWithdrawableSummary(message.message.isWithdrawableSummary);
        // setIsWithdrawableSummaryMassage(
        //   message.message.isWithdrawableSummaryMassage
        // );
        setIs_fixed_withdrawal_amount(
          message.message.is_fixed_withdrawal_amount
        );
      } else if (message.statusCode === 42) {
        localStorage.removeItem("login");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  const loadWithdrawPre = async () => {
    const actionIdReqWithdrawPreValid = "2057";

    const requestData = {};

    try {
      const message = await apiRequest(
        "FrontEndAuth",
        "post",
        actionIdReqWithdrawPreValid,
        requestData,
        navigate
      );
      //   console.log('message : ', message);

      if (message.statusCode === 1) {
        setBankAccountNumber(message.message.bankAccountNumber);
        setGetBankName(message.message.bankName);
        setGetBankShortName(message.message.bankShortName);
        setMemberBankImageURL(message.message.memberBankImageURL);
        setCurrentCashBalance(message.message.currentCashBalance);
        setAccountName(message.message.name);
        setMinimumWithdrawalAmount(message.message.minimumWithdrawalAmount);
        setIs_fixed_withdrawal_amount(
          message.message.is_fixed_withdrawal_amount
        );
        setWithdrawable2(message.message.withdrawable);
        setIsWithdrawableSummary(message.message.isWithdrawableSummary);
        setIsWithdrawableSummaryMassage(
          message.message.isWithdrawableSummaryMassage
        );
      } else if (message.statusCode === 42) {
        localStorage.removeItem("login");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  useEffect(() => {
    loadWithdrawPre();
    loadDataPlayerBalance();
  }, []);

  const handleSubmitModal = async (e) => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    if (isWithdrawableSummary) {
      if (withdrawable2 !== "" && withdrawable2 >= minimumWithdrawalAmount) {
        const actionIdReqWithdraw = 2020;
        const requestData = { CashWithdrawal: withdrawable2 };

        try {
          const message = await apiRequest(
            "FrontEndAuth",
            "post",
            actionIdReqWithdraw,
            requestData,
            navigate
          );

          if (message.statusCode === 1) {
            toast.success(message.description, {
              position: "bottom-right",
              autoClose: 5000,
            });
          } else {
            toast.error(message.description, {
              position: "bottom-right",
              autoClose: 10000,
            });
          }

          loadWithdrawPre();
        } catch (error) {
          console.error("Error loading data:", error);
        } finally {
          setIsSubmitting(false);
        }
      } else {
        toast.error(`การถอนเงินขั้นต่ำ ${minimumWithdrawalAmount} บาท`, {
          position: "bottom-right",
          autoClose: 5000,
        });
        setIsSubmitting(false);
      }
    } else {
      toast.error(isWithdrawableSummaryMassage, {
        position: "bottom-right",
        autoClose: 5000,
      });
      setIsSubmitting(false);
    }
  };

  return (
    <div className="d-block">
      <div className="menu_footer_f">
        <div className="navigation">
          <ul>
            <li className={`list ${activeMenu === "home" ? "active" : ""}`}>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuClick("home");
                  navigate("/dashboard");
                }}
              >
                <span className="icon">
                  {/* <i className="fa fa-home" aria-hidden="true" /> */}
                  <i className="fa fa-wallet" aria-hidden="true" />
                </span>
                <span className="text">กระเป๋าเงิน</span>
              </a>
            </li>
            <li className={`list ${activeMenu === "deposit" ? "active" : ""}`}>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenDepositClick();
                }}
              >
                <span className="icon">
                  <i className="fa fa-chevron-circle-up" aria-hidden="true" />
                </span>
                <span className="text">ฝากเงิน</span>
              </a>
            </li>

            <li className={`list ${activeMenu === "game" ? "active" : ""}`}>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuClick("game");
                  navigate("/lobby-huay");
                }}
              >
                <span className="icon p-1">
                  <i className="fa fa-gamepad" aria-hidden="true" />
                </span>
                <span className="text">เล่นเกมส์</span>
              </a>
            </li>
            <li className={`list ${activeMenu === "withdraw" ? "active" : ""}`}>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenWithdrawClick();
                }}
              >
                <span className="icon">
                  <i className="fa fa-chevron-circle-down" aria-hidden="true" />
                </span>
                <span className="text">ถอนเงิน</span>
              </a>
            </li>
            <li className={`list ${activeMenu === "promo" ? "active" : ""}`}>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuClick("promo");
                  navigate("/promotion");
                }}
              >
                <span className="icon">
                  <i className="fa fa-gift" aria-hidden="true" />
                </span>
                <span className="text">โปรโมชั่น</span>
              </a>
            </li>
            <div className="indicator">
              <div className="indicator-wrap" />
            </div>
          </ul>
        </div>
      </div>

      {/* DepositBox */}
      {showDeposit && (
        <div className="deposit_box up" id="DepositBox">
          <div className="head_box">กรุณาเลือกช่องทางฝากเงิน</div>
          {!localBankAccountNumber ? (
            <div className="detail_box">
              <div className="wrap_box">
                <h1>กรุณาอัพเดตข้อมูลสมาชิกก่อน</h1>
                <a
                  href="#"
                  className="button_link"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/register-profile");
                  }}
                >
                  <i className="fa fa-file" aria-hidden="true" /> คลิก!!!
                </a>
              </div>
            </div>
          ) : (
            <div className="detail_box">
              <div className="wrap_box">
                <div className="img_bank">
                  <img src={agentBankAccountImage} alt={agentBankShortName} />
                </div>
                <div className="text">
                  <h6>{agentBankAccountNumber}</h6>
                  <h6>{agentBankAccountName}</h6>
                </div>
                <a
                  href="#"
                  className="button_link"
                  onClick={(e) => {
                    e.preventDefault(); // ป้องกันการ reload หน้า
                    if (agentBankAccountNumber) {
                      navigator.clipboard.writeText(agentBankAccountNumber);
                      toast.success("คัดลอกหมายเลขบัญชีสำเร็จ!", {
                        position: "bottom-right",
                        autoClose: 5000,
                      });
                    } else {
                      toast.error("ไม่พบหมายเลขบัญชี!", {
                        position: "bottom-right",
                        autoClose: 5000,
                      });
                    }
                  }}
                >
                  <i className="fa fa-file" aria-hidden="true" /> คัดลอก
                </a>
              </div>
              <div className="wrap_box">
                <div className="img_bank">
                  <img
                    src={`assets/img/TrueMoney-Wallet-Icon-Logo-Vector.svg-.png`}
                    alt="TRUEMONEY"
                  />
                </div>
                <div className="text">
                  <h6>{agentTrueMoneyWalletID}</h6>
                  <h6>{agentTrueMoneyAccountName}</h6>
                </div>
                <a
                  href="#"
                  className="button_link"
                  onClick={(e) => {
                    e.preventDefault(); // ป้องกันการ reload หน้า
                    if (agentTrueMoneyWalletID) {
                      navigator.clipboard.writeText(agentTrueMoneyWalletID);
                      toast.success("คัดลอกหมายเบอร์สำเร็จ!", {
                        position: "bottom-right",
                        autoClose: 5000,
                      });
                    } else {
                      toast.error("ไม่พบหมายเลขบัญชี!", {
                        position: "bottom-right",
                        autoClose: 5000,
                      });
                    }
                  }}
                >
                  <i className="fa fa-file" aria-hidden="true" /> คัดลอก
                </a>
              </div>
            </div>
          )}
        </div>
      )}

      {/* WithdrawBox */}
      {showWithdraw && (
        <div className="withdraw_box up" id="WithdrawBox">
          <div className="head_box">กรุณาใส่จำนวนที่จะถอน</div>
          <div className="detail_box">
            <div className="wrap_box">
              <div className="img_bank">
                <img src={memberBankImageURL} alt={userBankShortName} />
              </div>
              <div className="text">
                <h6>{bankAccountNumber}</h6>
                <h6>{name}</h6>
              </div>
              <div className="text mt-2 mx-0 w-100 d-flex align-items-end justify-content-between">
                <h6>คงเหลือ</h6>
                <h4 className="mb-0 t-semiBold">
                  {currencyFormat(currentCashBalance2, 2)} ฿
                </h4>
              </div>
            </div>
            <div className="wrap_box">
              <input
                type="text"
                className="form-control"
                placeholder="จำนวนเงินที่ต้องการถอน"
                style={{ width: "75%" }}
                name="withdrawable2"
                value={withdrawable2}
                onChange={(event) =>
                  setWithdrawable2(event.target.value.replace(/\D/, ""))
                }
                readOnly={is_fixed_withdrawal_amount}
              />
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmitModal(e);
                }}
                className="button_link d-flex align-items-center justify-content-center"
                style={{ width: "23%", height: "100%" }}
              >
                <h6>ถอนเงิน</h6>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FooterAuth;
