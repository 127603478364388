import { createContext, useState, useContext } from 'react';

const OTPContext = createContext();

export const useOTP = () => useContext(OTPContext);

export const OTPProvider = ({ children }) => {
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false); // New state for OTP sent status

  return (
    <OTPContext.Provider
      value={{ isOTPVerified, setIsOTPVerified, isOtpSent, setIsOtpSent }}
    >
      {children}
    </OTPContext.Provider>
  );
};
